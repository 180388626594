import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import $ from "jquery"
import Metatags from "../../components/metatags"
import Navbar from "../../components/navbar"
import Gallery from "../../components/projects-gallery"
import MobGallery from "../../components/mob-gallery-comp"
import Footer from "../../components/footer"

const OficinaSwarowski = ({ data }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  const setVideoWindow = () => {
    setWindowWidth($(window).width())
  }

  useEffect(() => {
    setWindowWidth($(window).width())
    if (typeof window !== "undefined") {
      window.addEventListener("resize", setVideoWindow)
    }
  }, [])

  return (
    <>
      <Metatags
        title="daucodesigns | proyecto - oficina swarowski"
        description="detrás de cada producto, hay una historia"
        url="https://daucodesigns.com"
        image="https://daucodesigns.com/logo-daucodesigns.png"
        keywords="muebles, design"
      />
      <Navbar />
      <div className="height40" />
      <div className="height20" />
      <div id="project-header">
        <Container style={{ maxWidth: "1300px" }}>
          <Row>
            <Col>
              <div className="text-center projects">
                <h4 className="marcas-title nuestras-marcas-title">
                  oficina swarowski
                </h4>
                <h5>barcelona</h5>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="height40" />
      </div>
      {windowWidth >= 768 ? (
        <Gallery
          imagesArray={data.projectImages.nodes}
          company="oficina swarowski"
        />
      ) : (
        <MobGallery
          imagesArray={data.projectImages.nodes}
          ratio={765 / 1460}
          company="oficina swarowski"
          windowWidth={windowWidth}
          leftArrow={data.leftArrow}
          rightArrow={data.rightArrow}
        />
      )}
      <div className="height40" />
      <Container>
        <Row>
          <Col>
            <div className="text-center projects">
              <h5>Equilibrio en el eixample</h5>
            </div>
          </Col>
        </Row>
        <div className="height20" />
        <Row>
          <Col>
            <p>
              En estas oficinas ubicadas en un edificio modernista de Barcelona,
              era esencial encontrar el equilibrio entre tradición, modernidad y
              representatividad.
            </p>
            <p>
              Los colores de los tejidos debían encajar con los colores de los
              cristales modernistas que dan a la manzana interior, tan
              característico del Eixample de Barcelona.
            </p>
            <p>
              Se utilizaron muebles y sillas de oficina de Bene, butacas y sofás
              de Grassoler, alfombras de gran formato a medida y chispas de
              alegría con Slide.
            </p>
          </Col>
        </Row>
      </Container>
      <div className="height40" />
      <div className="height40" />
      <Footer />
    </>
  )
}

export default OficinaSwarowski

export const query = graphql`
  {
    leftArrow: file(relativePath: { eq: "left.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    rightArrow: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    projectImages: allFile(
      filter: { relativePath: { regex: "/projects/swarowski/" } }
      sort: { fields: relativePath }
    ) {
      nodes {
        childrenImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
    }
  }
`
